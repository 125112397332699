"use strict";

$(document).ready(function () {
    
    // scroll for anchors
    $("a[href*=\\#]:not([data-popup])").click(function(event) {
        
        var href = $(this).attr('href');
        
        if (href != '#') {
            
            var url = $(this).attr('href');
            var hash = url.substring(url.indexOf('#')+1);
            
            closeMenu();
            
            if ($('#' + hash).length) {
                $('html, body').animate({
                    scrollTop: $('#' + hash).offset().top
                }, 300);
            }

        }
        
    });
    
    $(".how__video").on('click', function (e) {
        e.preventDefault();
        console.log(1);
        console.log(window.video);
        $('.how__cover').html(window.video);
    });
    

    $('.header__menu').click(function (event) {
        toggleMenu(event);
    });

    $(document).bind('keydown', function(event) {
        if (event.which == 27) {
            $('.header__menu').removeClass('header__menu_opened');
            $('.nav').removeClass('nav_opened');
            $('.header').removeClass('header_opened');
            $('.toggle').removeClass('active');
        }
    });

    $('.faq__subtitle').click(function () {
        $(this).siblings().slideToggle(300);
    });

    $('.lazy').Lazy();

    var reviewsSwiper = new Swiper('.swiper-container-reviews', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        slideToClickedSlide: false,
        keyboard: false,
        allowTouchMove: false,
        noSwiping: true,
        navigation: {
            nextEl: '.swiper-container-reviews .reviews__nav_next',
            prevEl: '.swiper-container-reviews .reviews__nav_prev',
        },
        breakpoints: {
            1233: {
                allowTouchMove: true,
                slideToClickedSlide: true,
                noSwiping: false,
                keyboard: true,
            },
        }
    });

    var phoneSwiper = new Swiper('.swiper-container-phone', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        slideToClickedSlide: true,
        preloadImages: true,
        lazy: true,
        loop: true,
        navigation: {
            nextEl: '.swiper-container-phone .reviews__nav_next',
            prevEl: '.swiper-container-phone .reviews__nav_prev',
        },
    });
});



function toggleMenu (event) {
    event.preventDefault();

    if ($('.toggle').hasClass('active')) {
        closeMenu();
    } else {
        openMenu();
    }
}


function closeMenu () {
    $('.header__menu').removeClass('header__menu_opened');
    $('.nav').removeClass('nav_opened');
    $('.header').removeClass('header_opened');
    $('.toggle').removeClass('active');
}

function openMenu () {
    $('.header__menu').addClass('header__menu_opened');
    $('.nav').addClass('nav_opened');
    $('.header').addClass('header_opened');
    $('.toggle').addClass('active');
}